<template>
  <div>
    <Modal v-model="createInvestmentModel" width="800" :title="modalTitle">
      <Form
        ref="investmentModelForm"
        :model="investmentModel"
        autocomplete="on"
        name="oohforce-device-add"
        id="oohforce-device-add"
        :rules="ruleValidate"
        label-position="top"
      >
        <div style="font-size: 14px; line-height: 16px; margin: 10px 0px">
          项目需求
        </div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="8">
            <FormItem label="项目名称" prop="name">
              <Input
                v-model="investmentModel.name"
                size="small"
                placeholder="请输入项目名称"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="业态规划" prop="industryName">
              <Input
                v-model="investmentModel.industryName"
                size="small"
                placeholder="请输入业态规划"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="招商类别" prop="deviceType">
              <Input
                v-model="investmentModel.deviceType"
                size="small"
                placeholder="请输入招商类别"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="租赁期限（月）" prop="tenancy">
              <InputNumber
                v-model="investmentModel.tenancy"
                style="width: 100%"
                size="small"
                placeholder="请输入租赁期限"
              />
            </FormItem>
          </i-col>

          <i-col span="8">
            <FormItem label="报名方式" prop="enrollMethod">
              <Input
                v-model="investmentModel.enrollMethod"
                size="small"
                placeholder="请输入报名方式"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="交易保证金（元）" prop="depositAmount">
              <InputNumber
                v-model="investmentModel.depositAmount"
                style="width: 100%"
                size="small"
                placeholder="请输入交易保证金（元）"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="报名开始时间">
              <DatePicker
                type="datetime"
                size="small"
                transfer
                format="yyyy-MM-dd HH:mm"
                v-model="enrollStartDate"
                style="width: 100%"
                placeholder="选择报名开始时间"
              />
            </FormItem>
          </i-col>
          <i-col span="8">
            <FormItem label="报名截止时间">
              <DatePicker
                size="small"
                transfer
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                v-model="enrollEndDate"
                style="width: 100%"
                placeholder="选择报名截止时间"
              ></DatePicker>
            </FormItem>
          </i-col>
        </Row>
      </Form>
      <div slot="footer">
        <Button type="text" @click="handleReset">取消</Button>
        <Button type="primary" @click="handleSubmit">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'

import { ParseDateTime } from '@/utils/dateFormat'
/** new api */
import {
  createInvestmentProject,
  editInvestmentProject,
  getInvestmentProject
} from '@/api/invest/invest'
export default {
  components: {},
  props: {},
  data () {
    return {
      createInvestmentModel: false,
      modalTitle: '新增项目',

      enrollStartDate: '',
      enrollEndDate: '',

      investmentModel: {
        name: '',
        industryName: '',
        deviceType: '',
        tenancy: null,
        enrollStartDate: null,
        enrollMethod: null,
        depositAmount: null,
        enrollEndDate: null
      },
      ruleValidate: {
        name: [
          { required: true, type: 'string', message: ' ', trigger: 'change' }
        ],
        industryName: [
          { required: true, type: 'string', message: ' ', trigger: 'change' }
        ],
        deviceType: [
          { required: true, type: 'string', message: ' ', trigger: 'change' }
        ],
        enrollMethod: [
          { required: true, type: 'string', message: ' ', trigger: 'change' }
        ],
        tenancy: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ],
        depositAmount: [
          { required: true, type: 'number', message: ' ', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {},
  created () {},
  methods: {
    showModal () {
      this.modalTitle = this.investmentId ? '编辑项目' : '新增项目'
      this.initDefault()
      this.createInvestmentModel = true
    },
    handleReset () {
      this.createInvestmentModel = false
      this.$refs.investmentModelForm.resetFields()
    },
    initDefault () {
      if (this.investmentId) {
        getInvestmentProject({ investId: this.investmentId }).then((res) => {
          this.investmentModel.name = res.name
          this.investmentModel.deviceType = res.deviceType
          this.investmentModel.industryName = res.industryName

          this.investmentModel.enrollMethod = res.enrollMethod
          this.investmentModel.tenancy = res.tenancy
          this.investmentModel.depositAmount = res.depositAmount
          if (res.enrollStartDate) {
            this.investmentModel.enrollStartDate = res.enrollStartDate
            this.enrollStartDate = res.enrollStartDate
          }
          if (res.enrollEndDate) {
            this.investmentModel.enrollEndDate = res.enrollEndDate
            this.enrollEndDate = res.enrollEndDate
          }
        })
      } else {
        this.$refs.investmentModelForm.resetFields()
      }
    },

    handleSubmit () {
      this.$refs.investmentModelForm.validate((valid) => {
        if (valid) {
          this.$Modal.confirm({
            title: '操作提示',
            content: '请确认项目信息填写无误？',
            onOk: () => {
              if (this.enrollStartDate) {
                this.investmentModel.enrollStartDate = ParseDateTime(
                  this.enrollStartDate
                )
              } else {
                this.investmentModel.enrollStartDate = null
              }
              if (this.enrollEndDate) {
                this.investmentModel.enrollEndDate = ParseDateTime(
                  this.enrollEndDate
                )
              } else {
                this.investmentModel.enrollEndDate = null
              }
              if (this.investmentId) {
                this.investmentModel.id = this.investmentId
                editInvestmentProject(this.investmentModel).then((res) => {
                  if (res.success) {
                    this.$Notice.success({ desc: '操作成功' })
                    this.handleReset()
                    this.$store.commit('set_investment_update', new Date())
                  }
                })
              } else {
                this.investmentModel.id = null
                createInvestmentProject(this.investmentModel).then((res) => {
                  if (parseInt(res)) {
                    this.$Notice.success({ desc: '操作成功' })
                    this.handleReset()
                    this.$store.commit('set_investment_pageType', 'detail')
                    this.$store.commit(
                      'set_investment_investmentId',
                      parseInt(res)
                    )
                    this.$store.commit('set_investment_update', new Date())
                  }
                })
              }
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确输入项目信息' })
        }
      })
    }
  },
  computed: {
    investmentId () {
      return this.$store.state.investmentManage.investmentId
    },
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
