<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="6" :md="4" :lg="4" class="m-b-5">
        <DatePicker
          size="small"
          transfer
          :show-week-numbers="true"
          type="daterange"
          v-model="orderSchdule"
          @on-change="scheduleChange"
          style="width: 100%"
          placement="top-start"
          placeholder="报名周期"
        ></DatePicker>
      </i-col>

      <i-col :xs="24" :sm="6" :md="4" :lg="4" class="m-b-5">
        <Select
          v-model="query.status"
          size="small"
          placeholder="状态"
          clearable
        >
          <Option
            v-for="(item, index) in statusArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="6" :md="4" :lg="4" class="m-b-5">
        <i-input
          size="small"
          v-model="query.queryString"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="6" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
      <i-col :xs="24" :sm="24" :md="8" :lg="8" class="m-b-5">
        <a
          style="float: right; line-height: 30px"
          @click="handleCreateInvestment(0)"
          >+招商发起</a
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>

    <CreateInvestment ref="createModal"></CreateInvestment>
  </div>
</template>

<script>
import CreateInvestment from './modalComponents/Create.vue'

import {
  listInvestmentProject,
  submitInvestmentProject,
  offProject,
  deleteInvestmentProject
} from '@/api/invest/invest'

import { listDictLabel } from '@/api/contract/dict'

import { GetDateStr } from '@/utils/dateFormat'

export default {
  components: {
    CreateInvestment
  },
  data () {
    return {
      rentTypeArray: [],
      statusArray: [],
      templateArray: [],

      orderSchdule: [],
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 15,
        queryString: ''
      },
      tableData: [],
      columnManagedata: [
        // {
        //   title: '项目编号',
        //   key: 'code'
        // },
        {
          title: '项目名称',
          key: 'name'
        },
        {
          title: '业态规划',
          key: 'industryName'
        },
        { title: '招商类别', key: 'deviceType' },
        { title: '租赁期限(月)', key: 'tenancy' },
        { title: '交易保证金', key: 'depositAmount' },
        { title: '报名方式', key: 'enrollMethod' },
        { title: '开始报名时间', key: 'enrollStartDate' },
        { title: '结束报名时间', key: 'enrollEndDate' },
        { title: '状态', key: 'statusName' },
        { title: '报名数量', key: 'businessNumber' },
        { title: '签约客户', key: 'signBusinessName' },
        { title: '创建时间', key: 'createTime' },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$store.commit('set_investment_pageType', 'detail')
                    this.$store.commit(
                      'set_investment_investmentId',
                      params.row.id
                    )
                    // this.handleShowDetail(params.row.id)
                  }
                }
              },
              '详情'
            )
            const publicButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要上架【 ' + params.row.name + '】项目？</p>',
                      loading: true,
                      onOk: () => {
                        submitInvestmentProject({
                          investId: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.initPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '上架'
            )

            const editButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleCreateInvestment(params.row.id)
                  }
                }
              },
              '编辑'
            )
            const removeButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要下架【 ' + params.row.name + '】项目？</p>',
                      loading: true,
                      onOk: () => {
                        offProject({
                          investId: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.initPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '下架'
            )
            const deleteButton = h(
              'a',
              {
                style: {
                  color: 'red',
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.$Modal.confirm({
                      title: '操作确认？',
                      content:
                        '<p>确定要删除【 ' + params.row.name + '】项目？</p>',
                      loading: true,
                      onOk: () => {
                        deleteInvestmentProject({
                          investId: params.row.id
                        })
                          .then((res) => {
                            if (res && !res.errcode) {
                              this.$Notice.success({ desc: '操作成功' })
                              this.initPageData()
                            }
                            this.$Modal.remove()
                          })
                          .catch((error) => {
                            this.$Modal.remove()
                            this.$Notice.error({
                              title: '服务器请求错误',
                              desc: error
                            })
                          })
                      }
                    })
                  }
                }
              },
              '删除'
            )
            const returnBtns = []
            if (params.row.status === 1) {
              returnBtns.push(publicButton)
              returnBtns.push(editButton)
              returnBtns.push(deleteButton)
            }
            if (params.row.status === 3) {
              returnBtns.push(removeButton)
            }

            returnBtns.push(detailButton)
            return h('div', returnBtns)
          }
        }
      ]

    }
  },
  created () {
    this.initQuery()
    this.initPageData()
  },
  methods: {
    // 加载数据字典（查询条件）
    initQuery () {
      const that = this
      // 招商项目状态
      listDictLabel({ dictType: 'rs_invest_status' }).then((res) => {
        that.statusArray = res

        that.$store.commit('set_investment_statusArray', res)
      })
    },
    initPageData () {
      listInvestmentProject(this.query).then((response) => {
        this.total = response.total
        this.tableData = response.records
        this.query.pageNumber = response.current
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },

    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },

    handleCreateInvestment (investmentId) {
      this.$store.commit(
        'set_investment_investmentId',
        investmentId
      )
      this.$refs.createModal.showModal()
    }
  },
  watch: {
    beginUpdate () {
      this.initPageData()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.investmentManage.beginUpdate
    }
  }
}
</script>
